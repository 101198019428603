import { GetServerSidePropsContext } from 'next'
import { Cookies } from 'react-cookie'

import CookieService from '@/services/cookie.service'

import { auth } from './firebase.service'

const proxiedUrls = {
  '/api': `${process.env.NEXT_PUBLIC_EDHREC_API_URL}`,
}

// const NODE_ENV = process.env.NODE_ENV || 'development'

type GetArgs = []
export type Get = (...args: GetArgs) => Promise<Response>

type PostArgs = [string, Record<string, any>, RequestInit?, boolean?]
export type Post = (...args: PostArgs) => Promise<Response>

export class RequestService {
  abortControllers: Map<Promise<Response>, AbortController>
  cookies: { get: (key: string) => any }
  serverContext: GetServerSidePropsContext | null

  constructor(serverContext?: GetServerSidePropsContext) {
    this.abortControllers = new Map()
    this.serverContext = serverContext || null
    this.cookies = this.serverContext ? new Cookies(this.serverContext.req.cookies) : CookieService
  }

  private async getAuthToken() {
    if (this.serverContext) return this.serverContext.req.headers.authorization?.replace('Bearer ', '')

    return auth.currentUser?.getIdToken()
  }

  /**
   * Checks the front of the user supplied URL and changes the font of it to use our API_HOST
   * If the supplied URL doesn't match any of our proxies, return the provided URL
   */
  private replaceUrl(userUrl: string): string {
    let replacementUrl: string | null = null

    const proxy: string | undefined = Object.keys(proxiedUrls).find((key) => {
      if (userUrl.startsWith(key)) return true
      return false
    })

    if (!proxy) return userUrl
    if (proxy === '/api') replacementUrl = proxiedUrls['/api']

    // if (proxy === '/api' && NODE_ENV === 'production' && this.serverContext) replacementUrl = `${environment.serverUrl}/api`

    if (!replacementUrl) return userUrl

    return userUrl.replace(proxy, replacementUrl)
  }

  private fetchWrapper(url: string, request: any, abortable = false): Promise<Response> {
    const controller = new AbortController()
    const signal = controller.signal
    const userProxyInfo = this.serverContext?.req.headers['x-forwarded-for'] || undefined

    if (this.serverContext && userProxyInfo) {
      request.headers['x-forwarded-for'] = userProxyInfo
    }

    try {
      const response = fetch(url, { ...request, signal })
      if (abortable) this.abortControllers.set(response, controller)
      return response
    } catch (err) {
      console.error(`Failed to fetch ${url}`)
      console.error(`Request: ${JSON.stringify(request)}`)
      console.error(err)
      throw new Error(`Failed to fetch ${url}`)
    }
  }

  async abortAllRequests() {
    for (const promise of Array.from(this.abortControllers.keys())) {
      this.abortRequest(promise)
    }
  }

  async abortRequest(promise: Promise<Response>) {
    const controller = this.abortControllers.get(promise)
    if (controller) controller.abort('')
  }

  async get(url: string, options: RequestInit = {}, unauthenticated = false, abortable = false): Promise<Response> {
    const proxy = this.replaceUrl(url)

    const request: any = {
      headers: { accept: 'application/json' },
      method: 'GET',
      ...options, // Provides users a way to override everything
    }

    if (!unauthenticated) {
      const token = await this.getAuthToken()
      if (token) request.headers['authorization'] = `Bearer ${token}`
    }

    return this.fetchWrapper(proxy, request, abortable)
  }

  async post(
    url: string,
    body: Record<string, any>,
    options: RequestInit = {},
    unauthenticated = false,
    abortable = false,
  ): Promise<Response> {
    const proxy = this.replaceUrl(url)

    const request: any = {
      body: JSON.stringify(body),
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      method: 'POST',
      ...options,
    }

    if (!unauthenticated) {
      const token = await this.getAuthToken()
      if (token) request.headers['authorization'] = `Bearer ${token}`
    }
    return this.fetchWrapper(proxy, request, abortable)
  }

  async put(url: string, body: Record<string, any>, options: RequestInit = {}): Promise<Response> {
    const token = await this.getAuthToken()
    const proxy = this.replaceUrl(url)

    return this.fetchWrapper(proxy, {
      body: JSON.stringify(body),
      headers: { accept: 'application/json', authorization: `Bearer ${token}`, 'content-type': 'application/json' },
      method: 'PUT',
      ...options,
    })
  }

  async patch(url: string, body: Record<string, any>, options: RequestInit = {}): Promise<Response> {
    const token = await this.getAuthToken()
    const proxy = this.replaceUrl(url)

    return this.fetchWrapper(proxy, {
      body: JSON.stringify(body),
      headers: { accept: 'application/json', authorization: `Bearer ${token}`, 'content-type': 'application/json' },
      method: 'PATCH',
      ...options,
    })
  }

  async delete(url: string, options: RequestInit = {}): Promise<Response> {
    const authToken = await this.getAuthToken()
    const proxy = this.replaceUrl(url)

    return this.fetchWrapper(proxy, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      ...options,
    })
  }

  async upload(url: string, file: File, options: RequestInit = {}): Promise<Response> {
    const token = await this.getAuthToken()
    const proxy = this.replaceUrl(url)

    return this.fetchWrapper(proxy, {
      body: file,
      headers: {
        // accept: 'application/json',
        authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename="' + file.name + '"', // Not sure what this does, but our api is expecting it for some endpoints
        // 'Content-Type': 'application/json',
      },
      method: 'PUT',
      ...options,
    })
  }

  async formPost(
    url: string,
    body: Record<string, any>,
    options: RequestInit = {},
    unauthenticated = false,
  ): Promise<Response> {
    const proxy = this.replaceUrl(url)
    const formData = new FormData()

    for (const name in body) {
      if (Array.isArray(body[name])) {
        for (let i = 0; i < body[name].length; i++) {
          formData.append(`${name}[${i}]`, body[name][i])
        }
      } else {
        formData.append(name, body[name])
      }
    }

    const request: any = {
      body: formData,
      headers: { accept: 'application/json' },
      method: 'POST',
      ...options,
    }

    if (!unauthenticated) {
      const authToken = await this.getAuthToken()
      if (authToken) request.headers['authorization'] = `Bearer ${authToken}`
    }

    return this.fetchWrapper(proxy, request)
  }

  getFile(url: string): Promise<Response> {
    return this.fetchWrapper(url, {})
  }
}

const requestService = new RequestService()

export default requestService
