import { NextRouter } from 'next/router'

import { CardListType, CardType, RecCardType } from '@/types/card'
import { groupByModes, sortByModes } from '@/utils/constants'

const _numberFormatter = Intl.NumberFormat('en', { notation: 'compact' })

export const arrayBufferToBase64 = (buffer: number[] | ArrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const asPartnerLink = (card: CardType) =>
  card.partners_with && !['Amy Pond'].includes(card.name) // Exception for Amy Pond
    ? `/commanders/${formatUrl(card.name + '-' + card.partners_with)}`
    : `/${card.background_chooser || card.type.includes('Background') ? 'backgrounds' : 'partners'}/${
        card.sanitized_wo
      }`

export const bytesToBase64 = (input: string) => {
  const bytes = new TextEncoder().encode(input)
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('')
  return btoa(binString)
}

export const bulkExport = ({
  cardNames,
  cards,
  deckLines,
  medium,
  partner,
  vendor,
}: {
  cardNames?: string[]
  cards?: CardType[]
  deckLines?: string[]
  medium?: string
  partner?: string
  vendor: 'archidekt' | 'cardkingdom' | 'manapool' | 'moxfield' | 'tcgplayer' | 'tcgland'
}) => {
  if ([cards, cardNames, deckLines].filter((x) => x !== undefined).length > 1) {
    throw new Error('Only one of cards, cardNames, or deckLines can be provided')
  }

  if (cards) {
    cardNames = cards
      .map((card) => {
        if (!card.name.includes(' // ') || card.layout === 'split') return card.name
        return card.name.substring(0, card.name.indexOf(' // ')) // Remove transformed title text if it exists
      })
      .sort()
  }

  if (cardNames) {
    deckLines = cardNames.map((cardName) => `1 ${cardName}`)
  }

  // Now guaranteed to be a string array
  deckLines = deckLines as string[]

  switch (vendor) {
    case 'archidekt': {
      const newlineList = deckLines.join('\n')
      return `https://archidekt.com/cardImport?c=${encodeURIComponent(newlineList)}`
    }
    case 'cardkingdom':
      const newlineList = deckLines.join('\n')
      return `https://www.cardkingdom.com/builder?partner=${partner ? partner : 'edhrec'}&utm_source=edhrec${medium ? `&utm_medium=${medium}` : ''}&utm_campaign=edhrec&partner_args=edhrec${medium ? `,${medium}` : ''}&c=${encodeURIComponent(newlineList)}`
    case 'manapool': {
      const newlineList = deckLines.join('\n')
      return `https://manapool.com/add-deck?ref=scm&tap_s=5258585-0b68aa&tm_medium=edhrec,cardprice&deck=${bytesToBase64(newlineList).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')}`
    }
    case 'moxfield': {
      const newlineList = deckLines.join('\n')
      return `https://www.moxfield.com/import?c=${encodeURIComponent(newlineList)}`
    }
    case 'tcgplayer': {
      const doublePipeList = deckLines.join('||')
      return tcgplayerLink({
        subId1: medium ? `edhrec,${medium}` : 'edhrec',
        u: `https://store.tcgplayer.com/massentry?partner=${partner ? partner : 'EDHREC'}&utm_campaign=affiliate${medium ? `&utm_medium=${medium}` : ''}&utm_source=EDHREC${medium ? `&partner_args=${medium}` : ''}&c=${encodeURIComponent(doublePipeList)}`,
      })
    }
    case 'tcgland': {
      if (cardNames === undefined) {
        console.warn('TCG Land bulk export requires card names')
        return '#'
      }
      const pipeList = cardNames.join('|')
      return `https://www.tcg.land/multisearch#/magic-the-gathering?format=only_names&separator=|&lines=${encodeURIComponent(pipeList)}`
    }
  }
}

export const cardListsToNames = (cardLists: CardListType[]) => {
  const cards = cardLists.reduce((acc: RecCardType[], cardList) => [...acc, ...cardList.cardviews], [])
  return cards.flatMap((card) => (card.cards ? card.cards.map((c) => c.url) : card.sanitized_wo))
}

export const fetchUrl = async (name: string) => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/cards/${formatUrl(name)}`)
  const json = await res.json()
  const dir = json.legal_commander ? 'commanders' : 'cards'
  const url = `/${dir}/${json.url}`
  return url
}

export const flatten = (arr: any[]): any[] =>
  arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), [])

export const formatNumber = (num: number) => _numberFormatter.format(num)

export const formatUrl = (str: string) =>
  str
    .normalize('NFD')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-_+\s\|/]/g, '')
    .replace(/\+/g, 'plus ')
    .replace(/[\s/]+/g, '-')
    .replace(/\|/g, '/')

export const fullPath = (path: string | string[] | undefined) => (Array.isArray(path) ? path.join('/') : path)

export const getCardSize = (cardSize: 'xs' | 'sm' | 'lg' | 'xl' | 'md') => {
  switch (cardSize) {
    case 'xs':
      return { cardSize, height: 170, width: 122 }
    case 'sm':
      return { cardSize, height: 255, width: 183 }
    case 'lg':
      return { cardSize, height: 425, width: 305 }
    case 'xl':
      return { cardSize, height: 510, width: 366 }
    case 'md':
    default:
      return { cardSize, height: 340, width: 244 }
  }
}

export const isValidEmail = (str: string) =>
  str.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  )

export const mailtoBugReport = (stack?: string) => {
  if (!stack) return `mailto:bugreport@edhrec.com?subject=Bug%20Report`
  const body = encodeURIComponent(`\n\n--\n\n${window.location.href}\n\n${stack}`)
  return `mailto:bugreport@edhrec.com?subject=Bug%20Report&body=${body}`
}

export const modeKeyToValue = (key: string | undefined, type: 'group' | 'sort'): string | undefined => {
  if (!key) return
  if (type === 'group') return groupByModes.find((m) => m.key === key)?.value
  if (type === 'sort') return sortByModes.find((m) => m.key === key)?.value
}

export const redirectOnLogin = (router: NextRouter) => {
  if (
    router.query.redirect &&
    // Don't redirect back to these pages
    !['/', '/confirm-password', '/signin', '/signup'].includes(router.query.redirect as string)
  ) {
    router.push(router.query.redirect as string)
  } else {
    router.push('/account')
  }
}

export const scrollToTop = () => setTimeout(() => window.scrollTo(0, 0))

export const sha1Encode = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-1', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}

export const tcgplayerLink = ({ subId1, u }: { subId1: string; u: string }) => {
  const params = new URLSearchParams({ partnerpropertyid: '5237567', subId1, u })
  return 'https://partner.tcgplayer.com/c/4913290/1830156/21018?' + params.toString()
}
